import { Box } from '@mui/material'
import { useCurrentSubscription } from 'features/subscription'
import {
  useTrafficRuleBulkUpdateMutation,
  useTrafficRuleCreateMutation,
  useTrafficRuleDeleteMutation,
  useTrafficRuleUpdateMutation,
} from 'hooks'
import { TrafficRule, TrafficRuleCreatePayload, TrafficRuleType, WorkspaceEnvironment } from 'models'
import { useCallback } from 'react'

import { ampli } from '../../../../models/ampli'
import { AllowList } from '../AllowList/AllowList'
import { HeaderList } from '../HeaderList/HeaderList'

interface WebSectionProps {
  trafficRules: TrafficRule[]
  isCreationLimitExceeded: boolean
  isLoading: boolean
  currentEnvironment: WorkspaceEnvironment
}

export default function WebSection({
  trafficRules,
  isLoading,
  isCreationLimitExceeded,
  currentEnvironment,
}: WebSectionProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const { mutate: sendTrafficRuleCreateRequest } = useTrafficRuleCreateMutation()
  const { mutate: sendTrafficRuleUpdateRequest } = useTrafficRuleUpdateMutation()
  const { mutate: sendTrafficRuleBulkUpdateRequest } = useTrafficRuleBulkUpdateMutation()
  const { mutate: sendTrafficRuleDeleteRequest } = useTrafficRuleDeleteMutation()

  const handleCreateTrafficRule = useCallback(
    (data: TrafficRuleCreatePayload) => {
      const params = { subscriptionId }
      sendTrafficRuleCreateRequest({ data, params }, { onSuccess: () => ampli.httpHeaderRuleAdded() })
    },
    [sendTrafficRuleCreateRequest, subscriptionId]
  )

  const handleUpdateTrafficRule = useCallback(
    (data: TrafficRuleCreatePayload & { id: string }) => {
      const params = { subscriptionId, trafficRuleId: data.id }
      sendTrafficRuleUpdateRequest({ data, params }, { onSuccess: () => ampli.httpHeaderRuleUpdated() })
    },
    [sendTrafficRuleUpdateRequest, subscriptionId]
  )

  const handleBulkUpdateTrafficRules = useCallback(
    (entities: TrafficRuleCreatePayload[]) => {
      const params = { subscriptionId }
      const data = { type: TrafficRuleType.Origin, entities }
      sendTrafficRuleBulkUpdateRequest({ data, params }, { onSuccess: () => ampli.webOriginsConfigured() })
    },
    [sendTrafficRuleBulkUpdateRequest, subscriptionId]
  )

  const handleDeleteTrafficRule = useCallback(
    (trafficRuleId: string) => {
      const params = { subscriptionId, trafficRuleId }
      sendTrafficRuleDeleteRequest({ params }, { onSuccess: () => ampli.httpHeaderRuleDeleted() })
    },
    [sendTrafficRuleDeleteRequest, subscriptionId]
  )
  return (
    <Box rowGap='32px' display='flex' flexDirection='column'>
      <AllowList
        handleBulkUpdateRules={handleBulkUpdateTrafficRules}
        isLoading={isLoading}
        trafficRules={trafficRules}
        trafficRuleType={TrafficRuleType.Origin}
        isCreationLimitExceeded={isCreationLimitExceeded}
        currentEnvironment={currentEnvironment}
      />
      <HeaderList
        handleCreateHeaderRule={handleCreateTrafficRule}
        handleUpdateHeaderRule={handleUpdateTrafficRule}
        handleDeleteHeaderRule={handleDeleteTrafficRule}
        isLoading={isLoading}
        trafficRules={trafficRules}
        isCreationLimitExceeded={isCreationLimitExceeded}
        currentEnvironment={currentEnvironment}
      />
    </Box>
  )
}
