import { LinkButton } from '@compass/LinkButton'
import { OpenInNew } from '@mui/icons-material'
import { Box, Divider, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCurrentSubscription } from 'features/subscription'
import { useCertificates, useSubscription, useToast } from 'hooks'
import { useSubscriptionTokens, useTokenCreateMutation } from 'hooks/api/tokens'
import { ampli } from 'models/ampli'
import { PropsWithChildren, Suspense, useCallback } from 'react'

import { AnalyticsContext } from '../../../../models'
import { apiKeyDisplayNameMap } from '../../../apiKeys/components/KeySection/KeySection'
import { IntegrationMetadata } from '../../components/IntegrationMetadata/integrationMetadata'
import { IntegrationReadmeContext } from '../../components/IntegrationMetadata/MDX/IntegrationReadmeContext'
import { ApiKeyWidgetData } from '../../components/IntegrationMetadata/MDX/MDXApiKeyWidget/MDXApiKeyWidget'
import { createMDXBaseComponents } from '../../components/IntegrationMetadata/MDX/MDXBaseComponents'
import { getIntegrationReadmeProps } from '../../components/IntegrationMetadata/utils'
import { useIntegrationData } from '../../integrationHooks'
import GitHubIcon from '../Integration/github.svg'

function WithSubscription({
  integrationTag,
  children,
}: PropsWithChildren<{ integrationTag: IntegrationMetadata['integrationTag'] }>) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { showToast } = useToast()

  const { data: subscription } = useSubscription(currentSubscriptionId)
  const { data: subscriptionTokens } = useSubscriptionTokens(currentSubscriptionId)
  const { data: subscriptionCertificates } = useCertificates(currentSubscriptionId)
  const { integrationData } = useIntegrationData(currentSubscriptionId)
  const { mutate: sendCreateApiKeyRequest, isPending: isCreatingApiKey, data: secretApiKey } = useTokenCreateMutation()

  const mdxProps = getIntegrationReadmeProps(
    integrationTag,
    subscription,
    subscriptionTokens,
    subscriptionCertificates,
    integrationData,
    secretApiKey
  )

  const onGenerate = useCallback(
    (data: ApiKeyWidgetData) => {
      sendCreateApiKeyRequest(
        {
          data,
          params: { subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: (apiKeyData) => {
            ampli.apiKeyAdded({ apiKeyType: apiKeyDisplayNameMap[apiKeyData.type] })
          },
          onError: (e) => {
            showToast({ message: e.message, severity: 'error' })
          },
        }
      )
    },
    [sendCreateApiKeyRequest, currentSubscriptionId, showToast]
  )

  const keyWidgetProps = { onGenerate, apiKey: secretApiKey, isLoading: isCreatingApiKey }

  return (
    <IntegrationReadmeContext.Provider value={{ ...mdxProps, ...keyWidgetProps }}>
      {children}
    </IntegrationReadmeContext.Provider>
  )
}

type IntegrationGenericMDXProps = {
  integration: IntegrationMetadata
  needsSubscription?: boolean
}

export function IntegrationGenericMDX({ integration, needsSubscription = false }: IntegrationGenericMDXProps) {
  if (!integration.mdx) {
    throw new Error('Unsupported format')
  }

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const MDXComponentsWithContext = createMDXBaseComponents(AnalyticsContext.AccountSettings)

  return (
    <>
      <Paper>
        <Stack padding={3} spacing={3} divider={<Divider />}>
          <Stack direction='row' flexWrap='wrap' alignItems='center' gap={2}>
            <Paper variant='outlined'>
              <Box padding={3}>
                <integration.iconComponent style={{ width: '40px', height: '40px', display: 'block' }} />
              </Box>
            </Paper>

            <Box flexGrow={1}>
              <Typography variant='h1'> {integration.title}</Typography>
            </Box>

            {integration.sourceCodeLink && (
              <LinkButton fullWidth={smDown} href={integration.sourceCodeLink} target='_blank' variant='outline'>
                <GitHubIcon fontSize='inherit' />
                GitHub
              </LinkButton>
            )}

            {integration.docsLink && (
              <LinkButton fullWidth={smDown} href={integration.docsLink} target='_blank' variant='outline'>
                <OpenInNew fontSize='inherit' />
                View docs
              </LinkButton>
            )}
          </Stack>
          <Suspense>
            {needsSubscription ? (
              <WithSubscription integrationTag={integration.integrationTag}>
                <integration.mdx components={MDXComponentsWithContext} />
              </WithSubscription>
            ) : (
              <integration.mdx components={MDXComponentsWithContext} />
            )}
          </Suspense>
        </Stack>
      </Paper>
    </>
  )
}
